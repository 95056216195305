<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <defs>
      <path id="2qdma" d="M1330 551.7v-20h30v20z" />
      <path
        id="2qdmb"
        d="M1345.42 524.35c.32-.18.53-.54.53-.97 0-.6-.42-1.08-.95-1.08s-.95.48-.95 1.08c0 .43.21.8.53.97"
      />
      <path id="2qdmc" d="M1353.05 531.7l-7.62-7.35a.98.98 0 0 1-.85 0l-7.62 7.35" />
      <path id="2qdmd" d="M1330 546.94l9.49-7.69 9.95 7.69" />
      <path id="2qdme" d="M1346.04 544.3l4.58-4.11 9.38 6.75" />
      <path id="2qdmf" d="M1350.35 536.31a2.67 2.67 0 1 1 5.34 0 2.67 2.67 0 0 1-5.34 0z" />
    </defs>
    <g>
      <g transform="translate(-1329 -521)">
        <g>
          <g>
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#363636"
              stroke-miterlimit="50"
              stroke-width="1.5"
              xlink:href="#2qdma"
            />
          </g>
          <g>
            <g>
              <use
                fill="#fff"
                fill-opacity="0"
                stroke="#363636"
                stroke-miterlimit="50"
                stroke-width="1.5"
                xlink:href="#2qdmb"
              />
            </g>
            <g>
              <use
                fill="#fff"
                fill-opacity="0"
                stroke="#363636"
                stroke-miterlimit="50"
                stroke-width="1.5"
                xlink:href="#2qdmc"
              />
            </g>
          </g>
          <g>
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#363636"
              stroke-miterlimit="50"
              stroke-width="1.5"
              xlink:href="#2qdmd"
            />
          </g>
          <g>
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#363636"
              stroke-miterlimit="50"
              stroke-width="1.5"
              xlink:href="#2qdme"
            />
          </g>
          <g>
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#363636"
              stroke-miterlimit="50"
              stroke-width="1.5"
              xlink:href="#2qdmf"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheStandImageIcon',
};
</script>
